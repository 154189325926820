<template>
  <b-card>
    <div class="w-100 my-1 mb-2 float-left pl-1">
      <div class="d-flex align-items-center float-left">
        <!-- <a class="mr-50" @click="$router.go(-1)">
          <feather-icon icon="ChevronLeftIcon" size="20" />
        </a> -->
        <h3 class="m-0 font-weight-600">
          {{ appTitle || $t("questions.title") }}
        </h3>
      </div>
    </div>
    <div class="y-scroll-types-container" style="max-height: 90vh">
      <question-component-list :is-small-widget="isMobile" @isLoading="loading" />
    </div>
    <b-form
      v-if="canCreate && !isLoading"
      ref="questionForm"
      class="chat-app-form d-flex my-2"
      @submit.prevent="handleCreateQuestion"
    >
      <b-input-group class="input-group-merge form-send-message mr-1">
        <b-form-input
          v-model="questionName"
          :placeholder="$t('questions.message')"
        />
      </b-input-group>
      <b-button variant="primary" type="submit" style="width: 190px">
        {{ $t("send.button") }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import QuestionComponentList from './QuestionsComponentList.vue';

export default {
  name: 'QuestionsList',
  components: {
    QuestionComponentList,
  },
  mixins: [ToastificationContentVue],
  data() {
    return {
      questionName: '',
      isLoading: true,
    };
  },
  computed: {
    app() {
      return this.$store.getters.apps.apps[30];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canCreate() {
      return (
        this.isStaff
        || checkPermissions(
          'create',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    isMobile() {
      return window.innerWidth < 700;
    },
  },
  methods: {
    loading(isLoading) {
      this.isLoading = isLoading;
    },
    async handleCreateQuestion() {
      if (!this.questionName.trim()) {
        return;
      }
      try {
        const response = await this.$store.dispatch('createQuestion', {
          question: { name: this.questionName },
        });
        // this.fetchData();
        this.$refs.questionForm.reset();
        this.$socket.emit('newData', {
          itemType: 'questions',
          data: response.data,
          communitySlug: this.$store.getters.currentCollective.slug,
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>
<style>
.form-label {
  width: 88%;
}
@media only screen and (max-width: 991px) {
  .question-container {
    margin: 16px;
  }
}
@media only screen and (max-width: 420px) {
  .question-container {
    display: flex;
    flex-direction: column;
  }
  .right-icon {
    float: right;
  }
}
</style>
